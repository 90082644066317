<template>
  <div class="layout">
    <!-- 所有子页面路由容器 -->
    <transition name="route-fade">
      <keep-alive :include="cacheRoutes.map(item => item.name)">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState({
      cacheRoutes: 'cacheRoutes'
    }),
  }
}
</script>
<style lang="scss" scoped>
.layout {
  position: relative;
  height: 100%;
  width: 100%;
}
/* page切换动效 */
.route-fade-enter, .route-fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
  transition: all .3s
}
.route-fade-enter-to, .route-fade-leave {
  opacity: 1;
  transform: translateX(0px);
  transition: all .6s ease-in;
}

</style>